import React from 'react';
import logoFooter from '../image/logoFooter.png';
import '../css/Footer.css'; // Importer le fichier CSS

export default function Footer({ scrollToSolution, scrollToTarif, scrollToAvantages, scrollToEquipe, scrollToContact }) {

  const handleMenuClick = (scrollFunction) => (event) => {
    event.preventDefault();
    scrollFunction();
  };

  return (
    <div className="footerContainer" style={{ height: 400 }}>
      <div className="footerInnerContainer">
        <div className="footerSeparator"/>
        <div className="footerNav">
          <img src={logoFooter} alt="Logo" className="logo" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}/>
          <div className="navItemsFooter">
            <a href="/" onClick={handleMenuClick(scrollToSolution)}>La solution</a>
            <a href="/" onClick={handleMenuClick(scrollToTarif)}>Tarif</a>
            <a href="/" onClick={handleMenuClick(scrollToAvantages)}>Nos avantages</a>
            <a href="/" onClick={handleMenuClick(scrollToEquipe)}>Notre équipe</a>
            <a href="/" onClick={handleMenuClick(scrollToContact)}>Contact</a>
          </div>
        </div>
      </div>
    </div>
  );
}
