import React from 'react';
import '../css/TitleGrid.css'; // Assurez-vous d'importer le CSS approprié
import Typewriter from './Typewriter';
import macbook from '../image/macbook1.png'

function TitleGrid( {scrollToContact}) {
    return (
        <div className="titleGridContainer">
            <div className='content-grid'>
                <div className="text-block">
                    <div className="text-container">
                        <Typewriter />
                        <p className="subtitle">Le catalogue en ligne pour les professionnels du BTP</p>
                        <p className="subtitle-bis">Simple et rapide.</p>
                        <button className="contact-button" onClick={scrollToContact}>Demander une Démo</button>
                    </div>
                </div>
                
                <div className="image-block">
                    {/* Image avec rotation */}
                    <img src={macbook} alt="Macbook screen"/>
                </div>
            </div>
        </div>
    );
}

export default TitleGrid;
