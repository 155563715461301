import React, { useState } from "react";
import { Snackbar, Alert, TextField, Button, Icon } from '@mui/material';
import axios from "axios";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import '../css/Formulaire.css'; // Importer le fichier CSS

function Formulaire({}) {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [mail, setMail] = useState("");
  const [entreprise, setEntreprise] = useState("");
  const [message, setMessage] = useState("");
  const [mailError, setMailError] = useState(false);
  const [nomError, setNomError] = useState(false);
  const [prenomError, setPrenomError] = useState(false);

  const handleNomChange = (e) => {
    setNom(e.target.value);
    setNomError(e.target.value.trim() === "");
  };

  const handlePrenomChange = (e) => {
    setPrenom(e.target.value);
    setPrenomError(e.target.value.trim() === "");
  };

  const handleMailChange = (e) => {
    setMail(e.target.value);
    setMailError(!validateEmail(e.target.value));
  };

  const handleEntrepriseChange = (e) => setEntreprise(e.target.value);
  const handleMessageChange = (e) => setMessage(e.target.value);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const sendMessageToDiscord = async (nomContent, prenomContent, mailContent, entrepriseContent, messageContent) => {
    try {
      const response = await axios.post('https://regisse.fr/api/send-contact-message', {
        name: nomContent,
        surname: prenomContent,
        email: mailContent,
        entreprise: entrepriseContent,
        message: messageContent,
      });

      if (response.status === 200) { // Utiliser response.status pour vérifier le statut de la réponse
        setSnackbarMessage('Votre message a bien été envoyé, nous allons revenir vers vous rapidement.');
        setOpenSnackbar(true);
      } else {
        console.error('Erreur lors de l\'envoi du message');
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi du message', error);
    }
  };

  const handleSubmitMessage = async () => {
    let valid = true;

    if (nom.trim() === "") {
      setNomError(true);
      valid = false;
    }
    if (prenom.trim() === "") {
      setPrenomError(true);
      valid = false;
    }
    if (mail.trim() === "" || !validateEmail(mail)) {
      setMailError(true);
      valid = false;
    }
    if (message.trim() === "") {
      valid = false;
    }

    if (valid) {
      await sendMessageToDiscord(nom, prenom, mail, entreprise, message);
      setNom("");
      setPrenom("");
      setMail("");
      setEntreprise("");
      setMessage("");
    }
  };

  return (
    <div className="fContainer">
      <div className="fInnerContainer">
        <h1>Écrivez-nous</h1>
        <TextField
          margin="dense"
          id="nom"
          label="Nom*"
          type="text"
          multiline
          maxRows={3}
          value={nom}
          style={{ width: '90%' }}
          inputProps={{ maxLength: 55 }}
          error={nomError}
          sx={{
            '& label.Mui-focused': {
              color: 'rgba(79, 71, 220, 1)',
            },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: nomError ? 'red' : 'rgba(79, 71, 220, 1)',
              },
              '&.Mui-focused fieldset': {
                borderColor: nomError ? 'red' : 'rgba(79, 71, 220, 1)',
              },
              borderColor: nomError ? 'red' : 'rgba(0, 0, 0, 0.23)',
              borderRadius: 3,
              overflow: 'hidden',
            },
          }}
          onChange={handleNomChange}
        />

        <TextField
          margin="dense"
          id="prenom"
          label="Prénom"
          type="text"
          required
          multiline
          maxRows={1}
          value={prenom}
          style={{ width: '90%', marginTop: 13 }}
          inputProps={{ maxLength: 55 }}
          error={prenomError}
          sx={{
            '& label.Mui-focused': {
              color: 'rgba(79, 71, 220, 1)',
            },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: prenomError ? 'red' : 'rgba(79, 71, 220, 1)',
              },
              '&.Mui-focused fieldset': {
                borderColor: prenomError ? 'red' : 'rgba(79, 71, 220, 1)',
              },
              borderColor: prenomError ? 'red' : 'rgba(0, 0, 0, 0.23)',
              borderRadius: 3,
              overflow: 'hidden',
            },
          }}
          onChange={handlePrenomChange}
        />

        <TextField
          margin="dense"
          id="mail"
          label="Mail"
          type="email"
          multiline
          required
          maxRows={1}
          value={mail}
          style={{ width: '90%', marginTop: 13 }}
          inputProps={{ maxLength: 55 }}
          error={mailError}
          sx={{
            '& label.Mui-focused': {
              color: 'rgba(79, 71, 220, 1)',
            },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: mailError ? 'red' : 'rgba(79, 71, 220, 1)',
              },
              '&.Mui-focused fieldset': {
                borderColor: mailError ? 'red' : 'rgba(79, 71, 220, 1)',
              },
              borderColor: mailError ? 'red' : 'rgba(0, 0, 0, 0.23)',
              borderRadius: 3,
              overflow: 'hidden',
            },
          }}
          onChange={handleMailChange}
        />

        <TextField
          margin="dense"
          id="entreprise"
          label="Entreprise"
          type="text"
          multiline
          maxRows={1}
          value={entreprise}
          style={{ width: '90%', marginTop: 13 }}
          inputProps={{ maxLength: 55 }}
          sx={{
            '& label.Mui-focused': {
              color: 'rgba(79, 71, 220, 1)',
            },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: 'rgba(79, 71, 220, 1)',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'rgba(79, 71, 220, 1)',
              },
              borderRadius: 3,
              overflow: 'hidden',
            },
          }}
          onChange={handleEntrepriseChange}
        />

        <TextField
          margin="dense"
          id="message"
          label="Votre message"
          type="text"
          multiline
          required
          maxRows={1}
          value={message}
          style={{ width: '90%', marginTop: 13 }}
          inputProps={{ style: { height: 150 }, maxLength: 2000 }}
          sx={{
            '& label.Mui-focused': {
              color: 'rgba(79, 71, 220, 1)',
            },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: 'rgba(79, 71, 220, 1)',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'rgba(79, 71, 220, 1)',
              },
              borderRadius: 3,
              overflow: 'hidden',
            },
          }}
          onChange={handleMessageChange}
        />
        <Button style={{backgroundColor:'rgba(79, 71, 220, 1)', color:'white', width:220, height:50, borderRadius:16, marginTop:20}} onClick={handleSubmitMessage}>
          Envoyer
        </Button>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="info"
            sx={{
              width: '100%',
              backgroundColor: 'white',
              color: 'black',
              '& .MuiAlert-icon': {
                color: '#493657',
              },
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
      <div className="informationContainer">
        <h1>Besoin d'en savoir plus ?</h1>
        <span style={{whiteSpace:'balance'}}>Demande de devis, simple question ou prise de rendez-vous :</span>
        <div style={{display:'flex', flexDirection:'column'}}>
          <div className="numeroContainer">
            <Icon>
              <LocalPhoneIcon />
            </Icon>
            <span className="textInfoContainer">06 00 00 00 00 / 06 88 88 88 88</span>
          </div>
          <div className="emailContainer">
            <Icon>
              <EmailIcon />
            </Icon>
            <span className="textInfoContainer">contact@altop.fr</span>
          </div>
          <div className="adresseContainer">
            <Icon>
              <FmdGoodIcon />
            </Icon>
            <span className="textInfoContainer">30 rue de la galère, NYC, 95220</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Formulaire;
