import React, { useState, useEffect } from 'react';
import '../css/Typewriter.css';  // Assurez-vous d'importer le fichier CSS

const words = ["un perforateur", "un compacteur", "une pelleteuse"];  // Les mots à animer

function Typewriter() {
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [reverse, setReverse] = useState(false);
  const [blink, setBlink] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (subIndex === words[index].length && !reverse) {
        // Temps d'attente une fois le mot complet affiché
        setTimeout(() => setReverse(true), 1000);
      } else if (reverse && subIndex === 0) {
        setReverse(false);
        setIndex((prevIndex) => (prevIndex + 1) % words.length);
      } else if (reverse) {
        setSubIndex(subIndex - 1);
      } else {
        setSubIndex(subIndex + 1);
      }
    }, reverse ? 75 : 150);

    return () => clearTimeout(timeout);
  }, [subIndex, index, reverse]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setBlink((prev) => !prev);
    }, 500);
    return () => clearTimeout(timer);
  }, [blink]);

  return (
    <p className="typewriter">
      Cherchez et réservez <br></br> rapidement <span style={{ backgroundColor: "#DCDAF8" }}>{words[index].substring(0, subIndex)}</span><span className="cursor">{blink ? "|" : ""}</span>
    </p>
  );
}
export default Typewriter;
