import React from 'react';
import '../css/AvantageGrid.css'; // Remplacez par le chemin réel de votre fichier CSS
import Icon from '@mui/material/Icon'

import soudeur from '../image/soudeur.png'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

function TarifGrid() {
    return (
        <div class="aContainer">
            <div class="aInnerContainer">
                <div class="aImage">
                        <img src={soudeur} alt='soudeur'/>
                </div>
            
                <div class="aList">
                        <h1 style={{textAlign:'center'}}>Nos avantages</h1>
                        <div class="aUl">
                            <div class="aUlIconContainer">
                            <Icon class="aUlIcon">
                                <HomeRepairServiceIcon fontSize={'medium'} style={{color:'rgba(54, 48, 157, 1)', }}/>
                            </Icon>
                            </div>
                            <div class="aUlTextContainer">
                                <p class="aUlTextTitre">Des milliers de références du BTP</p>
                                <p class="aUlTextDesc">Comparez rapidement tous les produits des fournisseurs référencés sur Alt'op</p>
                            </div>
                        </div>

                        <div class="aUl">
                            <div class="aUlIconContainer">
                            <Icon class="aUlIcon">
                                <ShoppingCartCheckoutIcon fontSize={'medium'} style={{color:'rgba(54, 48, 157, 1)', }}/>
                            </Icon>
                            </div>
                            <div class="aUlTextContainer">
                                <p class="aUlTextTitre">Simplification des réservations</p>
                                <p class="aUlTextDesc">Créez votre panier puis réservez en un clic auprès de tous les fournisseurs</p>
                            </div>
                        </div>

                        <div class="aUl">
                            <div class="aUlIconContainer">
                            <Icon class="aUlIcon">
                                <LocationOnOutlinedIcon fontSize={'medium'} style={{color:'rgba(54, 48, 157, 1)'}}/>
                            </Icon>
                            </div>
                            <div class="aUlTextContainer">
                                <p class="aUlTextTitre">Cartographie des fournisseurs</p>
                                <p class="aUlTextDesc">Retrouvez les fournisseurs les plus proches de vous</p>
                            </div>
                        </div>

                        <div class="aUl">
                            <div class="aUlIconContainer">
                            <Icon class="aUlIcon">
                                <AssignmentOutlinedIcon fontSize={'medium'} style={{color:'rgba(54, 48, 157, 1)', }}/>
                            </Icon>
                            </div>
                            <div class="aUlTextContainer">
                                <p class="aUlTextTitre">Ajout régulier de nouveaux fournisseurs/produits</p>
                                <p class="aUlTextDesc">Description</p>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    );
}

export default TarifGrid;
