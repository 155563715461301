import React from 'react';
import '../css/SolutionGrid.css'; // Remplacez par le chemin réel de votre fichier CSS
import exampleImage from '../image/resultSVG.png'; // Remplacez par le chemin réel de votre image
import search from '../image/search-barSVG2.png';
import image1 from '../image/result-1SVG2.png';
import image2 from '../image/result-2SVG2.png';
import image4 from '../image/result-4SVG2.png';
import scrollingImage  from '../image/Filtres.png';
import card from '../image/cardSVG2.png';
import cart from '../image/cartSVG2.png';
import payement from '../image/payement.png';

function SolutionGrid() {
    return (
        <div class="sContainer">
            <div className="sInnerContainer">
                <h1 style={{textAlign:'center'}}>La solution</h1>
                <div className="solution-grid">
                    <div className="solution-image">
                        <img src={exampleImage} alt="Descriptive Alt Text" />
                    </div>
                    <div className="solution-text">
                        <p><strong>Alt’op</strong> propose l’un des <strong>premiers catalogues</strong> digitaux réunissant une multitude de <strong>fournisseurs du BTP et leurs produits.</strong></p>
                    </div>
                </div>
                <div className="solution-search">
                    <img src={search} alt="Descriptive Alt Text" style={{maxWidth:1160}}/>
                </div>
                <div className="solution-container">
                    <div className="left-grid">
                        <h1>1. Une recherche optimisée</h1>
                        <p>Trouvez le produit qui correspond aux besoins de votre chantier <br></br>facilement en 1 clic</p>
                    </div>
                    <div className="right-grid">
                        <img src={image1} alt="result 1" className="stacked-image base" />
                        <img src={image2} alt="result 2" className="stacked-image overlap first" />
                        <img src={image1} alt="result 3" className="stacked-image overlap second" />
                        <img src={image4} alt="result 4" className="stacked-image overlap third" />
                    </div>
                </div>
                <div className="result-grids">
                    <div className="result-left-grid">
                        <div className="scrolling-image-container">
                            <img src={scrollingImage} alt="Scrolling" />
                        </div>
                    </div>
                    <div className="result-right-grid">
                        <h1>2. Des filtres efficaces selon vos besoins</h1>
                        <p>Élargissez vos critères de recherche via des filtres dynamique en fonction des caractéristiques des produits</p>
                        <img src={card} alt="card" />
                    </div>
                </div>

                <div className="payment-new-container">
                    <div className="payment-left-grid">
                        <div style={{gap:24, textAlign:'center', whiteSpace:'balance'}}>
                            <h2 style={{marginTop: '70px'}}>3. Réservation simplifiée</h2>
                            <h2 style={{ color: '#4F47DC' }}>100% en ligne</h2>
                            <p style={{marginBottom: '70px'}}>Vérifiez, Cliquez, c’est reservé !</p>
                        </div>
                        <div className="payment-image-container">
                            <img src={payement} alt="payement" className="payment-card-image" />
                        </div>
                    </div>
                    <div className="payment-right-grid" style={{}}>
                        <img src={cart} alt="cart" className="payment-top-right-image" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SolutionGrid;
