import React, { useRef } from 'react';
import Navbar from './composant/Navbar';
import TitleGrid from './composant/TitleGrid';
import './App.css';
import down from './image/bouton-down.png';
import SolutionGrid from './composant/SolutionGrid';
import TarifGrid from './composant/TarifGrid';
import leroy from './image/leroySVG.png';
import loxam from './image/loxamSVG.png';
import kiloutou from './image/kiloutouSVG.png';
import AvantageGrid from './composant/AvantageGrid';
import RolesGrid from './composant/RolesGrid';
import Formulaire from './composant/Formulaire';
import Footer from './composant/Footer';

function App() {
  const gradientContainerRef = useRef(null);
  const tarifGridRef = useRef(null);
  const avantageGridRef = useRef(null);
  const rolesGridRef = useRef(null);
  const formulaireRef = useRef(null);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="container">
      <Navbar
        scrollToSolution={() => scrollToRef(gradientContainerRef)}
        scrollToTarif={() => scrollToRef(tarifGridRef)}
        scrollToAvantages={() => scrollToRef(avantageGridRef)}
        scrollToEquipe={() => scrollToRef(rolesGridRef)}
        scrollToContact={() => scrollToRef(formulaireRef)}
      />
      <TitleGrid scrollToContact={() => scrollToRef(formulaireRef)}/>
      <div className="centered-image" onClick={() => scrollToRef(gradientContainerRef)}>
        <img src={down} alt="Scroll Down" width="50" height="50" style={{ cursor: 'pointer' }} />
      </div>
      <div ref={gradientContainerRef} className="gradient-container">
        <SolutionGrid />
      </div>
      <div className="trusted-suppliers-banner" style={{ justifyContent: 'space-evenly', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'rgba(79, 71, 220, 0.1)', height: 150 }}>
        <h2 style={{ textAlign: 'center', marginTop: 0 }}>Des fournisseurs de confiance</h2>
        <div className="logos">
          <div className="logos-slide">
            <img src={loxam} alt="Logo 1" />
            <img src={kiloutou} alt="Logo 2" />
            <img src={leroy} alt="Logo 3" />
            <img src={loxam} alt="Logo 1" />
            <img src={kiloutou} alt="Logo 2" />
            <img src={leroy} alt="Logo 3" />
            <img src={loxam} alt="Logo 1" />
            <img src={kiloutou} alt="Logo 2" />
            <img src={leroy} alt="Logo 3" />
            <img src={loxam} alt="Logo 1" />
            <img src={kiloutou} alt="Logo 2" />
            <img src={leroy} alt="Logo 3" />
          </div>
          <div className="logos-slide">
            <img src={loxam} alt="Logo 1" />
            <img src={kiloutou} alt="Logo 2" />
            <img src={leroy} alt="Logo 3" />
            <img src={loxam} alt="Logo 1" />
            <img src={kiloutou} alt="Logo 2" />
            <img src={leroy} alt="Logo 3" />
            <img src={loxam} alt="Logo 1" />
            <img src={kiloutou} alt="Logo 2" />
            <img src={leroy} alt="Logo 3" />
            <img src={loxam} alt="Logo 1" />
            <img src={kiloutou} alt="Logo 2" />
            <img src={leroy} alt="Logo 3" />
          </div>
        </div>
      </div>
      <div ref={tarifGridRef}>
        <TarifGrid />
      </div>
      <div ref={avantageGridRef}>
        <AvantageGrid />
      </div>
      <div ref={rolesGridRef}>
        <RolesGrid />
      </div>
      <div ref={formulaireRef}>
        <Formulaire />
      </div>
      <Footer
        scrollToSolution={() => scrollToRef(gradientContainerRef)}
        scrollToTarif={() => scrollToRef(tarifGridRef)}
        scrollToAvantages={() => scrollToRef(avantageGridRef)}
        scrollToEquipe={() => scrollToRef(rolesGridRef)}
        scrollToContact={() => scrollToRef(formulaireRef)}
      />
    </div>
  );
}

export default App;
