import React from 'react';
import ChristopherCard from '../image/ChristopherCardSVG2.png';
import AlexisCard from '../image/AlexisCardSVG2.png';
import '../css/RolesGrid.css';

function RolesGrid() {
    return (
        <div class="rgContainer">
            <div class="rgInnerContainer">
                <h1 style={{textAlign:'center'}}>Qui sommes nous ?</h1>
                <p style={{textAlign:'center'}}>XXX <br /> XXX</p>
                <div class="rgImages">
                    <img src={AlexisCard} alt={'Présentation d\'Alexis'}/>
                    <img src={ChristopherCard} alt={'Présentation de Christopher'}/>
                </div>
            </div>

        </div>
    );
}

export default RolesGrid;
