import React from 'react';
import '../css/TarifGrid.css'; // Remplacez par le chemin réel de votre fichier CSS
import tarif from '../image/tarifSVG.png'
import tarif1 from '../image/tarif1SVG2.png'
import tarif2 from '../image/tarif2SVG2.png'
import tarif3 from '../image/tarif3SVG2.png'
import tarif4 from '../image/tarif4SVG2.png'

import Grid from '@mui/material/Grid'

function TarifGrid() {
    return (
        <div class="tContainer">
            <h1>
                Nos Tarifs
            </h1>
            <div style={{textAlign:'center', width:'90%'}}>
                <p style={{whiteSpace:'balance'}}>Une tarification <strong>simple</strong> avec une période d’essaie d’un mois gratuit. Les tarifs s’appliquent à chaque utilisateurs</p>
                <p style={{whiteSpace:'balance'}}>Chaque option est cumulable, permettant de compléter la solution au maximum de ses capacités.</p>
            </div>
            <div>
                <img class="tarifs" src={tarif} alt='Les différents tarifs'/>
            </div>
            <div class="tarifContainer">
                <Grid container spacing={0} alignItems="center" style={{ width:'auto',}}>
                    <Grid item xs={12} sm={6} md={3} style={{display:'flex', justifyContent:'center',}}>
                        <img className="tarif" src={tarif1} alt="Tarif Standard" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                        <img className="tarif" src={tarif2} alt="Tarif Perso" />
                        <span style={{color: 'rgba(79, 71, 220, 1)', fontWeight:'bold'}}>Option cumulable</span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                        <img className="tarif" src={tarif3} alt="Tarif Réservation en ligne" />
                        <span style={{color: 'rgba(79, 71, 220, 1)', fontWeight:'bold'}}>Option cumulable</span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                        <img className="tarif" src={tarif4} alt="Tarif Gestion Financière" />
                        <span style={{color: 'rgba(79, 71, 220, 1)', fontWeight:'bold'}}>Option cumulable</span>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default TarifGrid;
