import React, { useState } from 'react';
import '../css/Navbar.css';
import logo from '../image/logoSVG.png';

function Navbar({ scrollToSolution, scrollToTarif, scrollToAvantages, scrollToEquipe, scrollToContact }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = (scrollFunction) => (event) => {
    event.preventDefault();
    scrollFunction();
    setIsOpen(false); 
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className="navbar">
      <img src={logo} alt="Logo" className="logo" />
      <button className="hamburger" onClick={toggleMenu}>
        &#9776;
      </button>
      
      <div className={`nav-items ${isOpen ? 'open' : ''}`}>
        {isOpen && (
          <button className="close-button" onClick={closeMenu}>
            &times;
          </button>
        )}
        <a href="/" onClick={handleMenuClick(scrollToSolution)}>La solution</a>
        <a href="/" onClick={handleMenuClick(scrollToTarif)}>Tarif</a>
        <a href="/" onClick={handleMenuClick(scrollToAvantages)}>Nos avantages</a>
        <a href="/" onClick={handleMenuClick(scrollToEquipe)}>Notre équipe</a>
        <a href="/" onClick={handleMenuClick(scrollToContact)}>Contact</a>
      </div>
    </div>
  );
}

export default Navbar;
